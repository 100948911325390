import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog44/Top-ACL-Rehab-San-Diego.jpg";
import image2 from "../../images/blog44/Grade-3-ACL-Tear-Recovery-Time.jpg";
import image3 from "../../images/blog44/Returning-to-play-after-ACL-surgery.jpg";
import image4 from "../../images/blog44/Best-ACL-Physical-Therapy-San-Diego.jpg";

export default function osteroporosisHowToBuildStrongerBones(){
    return(
        <BlogPostOld
            src={image}
            title={"San Diego ACL Recovery: How Long Does it Take for ACL Tears to Heal?"}
            summary={"This is one of the most common questions we get asked. Everyone wants to know how long does it take for ACL injuries to heal and how long will it take to get back to their sport after an ACL surgery. The answer to this question may surprise you."}
            date={"Dec. 9, 2024"}
            author={"Dr. Donald Mull, DC"}
            // InStyle={"max-h-full"}

        >
            <>
  <p>
    This is one of the most common questions we get asked. Everyone wants to
    know how long does it take for ACL injuries to heal and how long will it
    take to get back to their sport after an ACL surgery. The answer to this
    question may surprise you.
  </p>
  <p>
    It was a normal weekend. The team has been looking great this past month and
    is really looking forward to this round of playoff competition. Once the
    game starts it is apparent that it is much more competitive than the team
    have recently faced and as the game continues deep into the second half, it
    is coming down to the wire.
  </p>
  <p>
    With the game on the line you watch in anticipation as your daughter takes a
    side step to avoid the defender’s tackle when she drops and immediately
    grabs her knee in pain. She is unable to finish and the fear is she has torn
    her ACL. Many of her teammates have experienced this but fingers are crossed
    that it is not that bad.
  </p>
  <img src={image2} alt='Grade 3 ACL Tear Recovery Time' />
  <p>
    Traditionally when an athlete injures their ACL, they consult with an
    orthopedic surgeon to confirm diagnosis with an MRI or fancy picture of the
    knee joint that can show the health of the ligament. Upon receiving that gut
    wrenching confirmation of an ACL tear, a date is set to perform a surgical
    repair (often using a tendon from the hamstring or patellar tendon).
  </p>
  <img src={image3} alt='Returning to play after ACL surgery' />
  <p>
    This news often is accompanied by a slew of emotions and concerns and
    rightfully so. ACL injuries are dreaded by all athletes and it wasn’t that
    long ago when it was considered to be career ending. However, that is no
    longer the case and it is now more possible than ever to come back from ACL
    injuries. With that being said, the surgery and how the athlete rehabs plays
    a crucial role in how quickly and more importantly, safely the athlete
    returns to sport.
  </p>
  <p>
    Once an athlete is diagnosed and the surgical procedure date has been
    selected, the next best thing is to immediately start the rehab process. The
    process can be very overwhelming and concerning but getting straight to work
    for an athlete is invaluable. A great place to start is by getting a
    baseline of the strength of the non injured leg before it weakens because of
    rest. This is something we cover here in the first part of our ACL rehab
    blog series.
  </p>
  <p>
    So, how long will it take for an ACL injury to heal? First and foremost, the
    answer to this question has MANY variables and varies depending on who is
    asking the question. Young athletes (middle school, high school and college
    ages) are among the most affected populations by ACL injuries(1), so this
    article will focus here.
  </p>
  <p>
    Injured athletes and parents often have one main concern; when can that
    athlete get back on the court or field and do so with confidence and as
    little risk of re-injury as possible. With so many outside pressures like
    potential scholarships, other kids continuing to play, the next season
    coming fast, etc the stakes feel higher than ever to get back to playing as
    fast as humanly possible. But what is the right thing to do? When should an
    athlete safely come back to play after an ACL tear and ACL surgery?
  </p>
  <p>
    Traditionally, the timeline that is provided to athletes and their parents
    when consulting with physicians is nine months to a year. When parents and
    athletes hear this, it is concerning to think about all of the missed
    tournaments, games and fun with their friends on the field/court. This can
    be really frustrating to parents and heartbreaking to athletes, especially
    if there lacks a clear understanding and rationale as to why it does indeed
    take this long (and for some even longer) to come back and play after an ACL
    injury.
  </p>
  <p>
    Without a rationale as to why it takes this long, the number feels arbitrary
    and very general. So let me explain a little more as to why it takes this
    long to heal from an ACL injury.
  </p>
  <p>
    A very popular study has shown that when an athlete is returning prior to 9
    months it comes with exponential risks in re-injury. To be more specific,
    for every month an athlete delayed returning to sport there was a 51%
    decreased risk for re-injury all the up to 9 months.(2) This is an
    indication that as timelines go, the expectation needs to be set that the
    process will indeed take at least nine months. However, this is often
    misunderstood to mean that time alone is the biggest indicator to get back
    on the field.
  </p>
  <p>
    <em>
      Timelines may be a good Northstar when setting expectations on how long it
      takes to play after an ACL surgery but they do nothing to tell us how
      ready an athlete is to bet back on the field or court from a physical and
      mental standpoint.{" "}
    </em>
  </p>
  <p>
    What is helpful for an athlete to stay focused on the rehab process is
    creating milestones that become goals to hit during the rehab process. This
    becomes the proof of concept. For example during the early phases, the
    number one goals are to minimize swelling as much as humanly possible,
    restore range of motion as much as possible, regain quad contraction and
    control as well as restore gait.
  </p>
  <p>
    Each phase of the rehab process should be marked by milestones that an
    athlete can achieve, like leveling up in a video game. By the end the
    athlete should be as strong as the non-injured leg, jump as high and as
    powerfully as the non-injured leg, look athletic when sprinting, cutting and
    jumping and finally have no doubt in their body’s ability to compete at high
    levels.
  </p>
  <img src={image4} alt='Best ACL Physical Therapy San Diego' />
  <p>
    From an athlete’s perspective, having goals to train for gives them a
    purpose and an achievable plan to keep them engaged with laser focus. Those
    athletes who are able to achieve a variety of strength and power tasks prior
    to returning to sport have less risk of reinjury(3). This is why we use this
    as criteria to earn time on the court or field.
  </p>
  <p>
    Returning to a sport is earned by how ready the athlete is, not by waiting a
    certain amount of time. Therefore, the rehab process should be meticulously
    planned to prepare the athlete for the high demands of their sport via
    strengthening muscles of the entire leg, lifting heavy weights to expose to
    higher forces, quality movement strategies to avoid compensation, sprint
    drills/jumping/cutting to expose to fast forces, and continual coaching
    feedback.
  </p>
  <p>
    If your athlete is not getting this level of care, we are here for you. Book
    a phone call to see how we can get your athlete started on a program to
    rehab like a pro athlete! 
  </p>
  <div className='mx-auto flex justify-center'>
  <a className={"px-4 mt-4"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center text-white"}>Book Now </button></a>
  </div>
  <p>Sources</p>
  <ol>
    <li>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://pubmed.ncbi.nlm.nih.gov/26920430/"
        >
          <span style={{ color: "rgb(17, 85, 204)" }}>
            Sanders TL, Maradit Kremers H, Bryan AJ, Larson DR, Dahm DL, Levy
            BA, Stuart MJ, Krych AJ. Incidence of Anterior Cruciate Ligament
            Tears and Reconstruction: A 21-Year Population-Based Study. Am J
            Sports Med. 2016 Jun;44(6):1502-7. doi: 10.1177/0363546516629944.
            Epub 2016 Feb 26. PMID: 26920430.
          </span>
        </a>
      </p>
    </li>
    <li>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://pmc.ncbi.nlm.nih.gov/articles/PMC4912389/"
        >
          <span style={{ color: "rgb(17, 85, 204)" }}>
            Grindem H, Snyder-Mackler L, Moksnes H, Engebretsen L, Risberg MA.
            Simple decision rules can reduce reinjury risk by 84% after ACL
            reconstruction: the Delaware-Oslo ACL cohort study. Br J Sports Med.
            2016 Jul;50(13):804-8. doi: 10.1136/bjsports-2016-096031. Epub 2016
            May 9. PMID: 27162233; PMCID: PMC4912389.
          </span>
        </a>
      </p>
    </li>
    <li>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://pubmed.ncbi.nlm.nih.gov/28990491/#:~:text=Conclusion%20The%20proportions%20of%20young,than%20those%20who%20did%20not."
        >
          <span style={{ color: "rgb(17, 85, 204)" }}>
            Toole AR, Ithurburn MP, Rauh MJ, Hewett TE, Paterno MV, Schmitt LC.
            Young Athletes Cleared for Sports Participation After Anterior
            Cruciate Ligament Reconstruction: How Many Actually Meet Recommended
            Return-to-Sport Criterion Cutoffs? J Orthop Sports Phys Ther. 2017
            Nov;47(11):825-833. doi: 10.2519/jospt.2017.7227. Epub 2017 Oct 7.
            PMID: 28990491.
          </span>
        </a>
      </p>
    </li>
  </ol>
</>









        </BlogPostOld>

    )
}